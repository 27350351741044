var FRESHCHAT_TOKEN = 'f69ba3ff-51e9-4762-b80e-79d481013c7f';
var primaryColor = '#F4BA5F';

function initFreshChat() {
    fetch(`${window.ff.baseURL}api/FF.Localization/localization/get?module=FF.OrchardTheme.Chat&language=${window.ff.localization}`)
        .then(resp => {
            return resp.json();
        })
        .then(resp => {
            var initObject = {
                token: FRESHCHAT_TOKEN,
                host: "https://wchat.eu.freshchat.com",
                tags: ['public_site'],
                cssNames: {
                    widget: 'fc_frame',
                    open: 'fc_open',
                    expanded: 'fc_expanded'
                },
                config: {
                    disableEvents: true,
                    // showFAQOnOpen: true,
                    // hideFAQ: false,
                    headerProperty: {
                        backgroundColor: primaryColor
                    }
                }
            };

            if (resp.isSuccess == true && resp.model.length > 0) {
                initObject.config.content = {
                    placeholders: {
                        search_field: resp.model['placeholders-search-field'],
                        reply_field: resp.model['placeholders-reply-field'],
                        csat_reply: resp.model['placeholders-csat-reply']
                    },
                    actions: {
                        csat_yes: resp.model['actions-csat-yes'],
                        csat_no: resp.model['actions-csat-no'],
                        push_notify_yes: resp.model['actions-push-notify-yes'],
                        push_notify_no: resp.model['actions-push-notify-no'],
                        tab_faq: resp.model['actions-tab-faq'],
                        tab_chat: resp.model['actions-tab-chat'],
                        csat_submit: resp.model['actions-csat-submit']
                    },
                    headers: {
                        chat: resp.model['headers-chat'],
                        chat_help: resp.model['headers-chat-help'],
                        faq: resp.model['headers-faq'],
                        faq_help: resp.model['headers-faq-help'],
                        faq_not_available: resp.model['headers-faq-not-available'],
                        faq_search_not_available: resp.model['headers-faq-search-not-available'],
                        faq_useful: resp.model['headers-faq-useful'],
                        faq_thankyou: resp.model['headers-faq-thankyou'],
                        faq_message_us: resp.model['headers-faq-message-us'],
                        push_notification: resp.model['headers-push-notification'],
                        csat_question: resp.model['headers-csat-question'],
                        csat_yes_question: resp.model['headers-csat-yes-question'],
                        csat_no_question: resp.model['headers-csat-no-question'],
                        csat_thankyou: resp.model['headers-csat-thankyou'],
                        csat_rate_here: resp.model['headers-csat-rate-here'],
                        channel_response: {
                            offline: resp.model['channel-response-offline'],
                            online: {
                                minutes: {
                                    one: resp.model['channel-response-online-minutes-one'],
                                    more: resp.model['channel-response-online-minutes-more']
                                },
                                hours: {
                                    one: resp.model['channel-response-online-hours-one'],
                                    more: resp.model['channel-response-online-hours-more']
                                }
                            }
                        }
                    }
                };

            }
            initObject.locale = window.ff.localization != null ? window.ff.localization : 'en';

            try {
                if (window.fcWidget != null) {
                    window.fcWidget.init(initObject);
                }
            } catch (e) {
                console.error(e);
                // some catch logics
            }
        },)
        .catch(err => console.error('cant initialize localization'));
};

function initialize(i, t) {
    var e;
    i.getElementById(t) ? initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0, e.src = "https://wchat.eu.freshchat.com/js/widget.js", e.onload = initFreshChat, i.head.appendChild(e))
}

function initiateCall() {
    initialize(document, "freshchat-js-sdk")
}


