$.removeSticky = function () {
    setTimeout(function () {
        $("#layout-navigation").removeClass("sticky");
    }, 100);
};

$.goTo = function (nodename) {

    var current = $(nodename);
    var scrollToElement = function (el, ms) {
        var speed = (ms) ? ms : 600;
        $('html,body').animate({
            scrollTop: $(el).offset().top
        }, speed);
    }


    if (current) {

        try {
            scrollToElement(nodename, 500);
        } catch (e) {
            console.error("slide error", e);
        }

    } else {
        console.error("can't slide");
    }
}

$(document).ready(function () {

    /* jQuery scroll to top plugin start */

    $.topbutton({
        htlm: "⇈", //String
        css: "", //String
        scrollAndShow: false, //Boolean
        scrollSpeed: 150, //Number
        scrollAndShow: true
    });

    $('.topButton').click( ()=>{
        ffScroll();
    })


    window.onscroll = function () {
        stickyNavbar()
    };
    window.onload = function () {
        stickyNavbar()
    };

    var navbar = document.querySelector("#layout-navigation nav"); // Get the navbar
    var sticky = navbar.offsetTop; // Get the offset position of the navbar


    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function stickyNavbar() {
        if (window.pageYOffset > sticky) {
            document.querySelector("#layout-navigation").classList.add("sticky");
            document.querySelector(".zone.zone-navigation").classList.add("bg-light");
        } else {
            document.querySelector(".zone.zone-navigation").classList.remove("bg-light");
            document.querySelector("#layout-navigation").classList.remove("sticky");
        }
    }

    /* Contact US Submitting form ( Do you have questions about Kaymaks? )*/
    function toggleCheck() {
        $("#QuestionFormSubmit").prop('disabled', function (i, v) {
            return !v;
        });
    };
    $('#QuestionFormSubmitCheckbox').change(function () {
        toggleCheck();
    });


    if ($(window).scrollTop() >= 1) // Проверка состояния прокрутки
    {
        $("html, body").animate({
            scrollTop: 0
        }, "slow");
    }

    
});
